<template>
    <div class="layout" @click.stop="changeState">
        <!-- 导航栏 -->
        <div class="topbar">
            <div class="container">
                <ul class="nav">
                    <li>
                       <router-link :to="{ name: 'study', params: {} }"><i class="iconfont icon-home"></i>首页</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'papers_test', params: {} }"><i class="iconfont icon-viewcomfortable"></i>考试中心</router-link>
                    </li>
                    <li>
                        <a href="#"><i class="iconfont icon-me1"></i>用户中心</a>
                    </li>
                    <li>
                        <a href="#" @click="relesh"
                        ><i class="el-icon-refresh-left" style="font-size: 18px;vertical-align: -3px;"></i>刷新</a
                        >
                    </li>
                    <li>
                        <a href="#" @click="logout"
                        ><i class="iconfont icon-planefill"></i>退出</a
                        >
                    </li>
                </ul>
            </div>
        </div>
        <!-- 心铭远单词速记title -->
        <div class="header-box">
            <div class="container" style="padding: 0">
                <div class="header clearfix">
                    <div class="pull-left" style="width: 15%">
                        <div class="logo">
                            <a href="#"
                            ><img src="~@/assets/images/study/logohw.png" alt=""
                            /></a>
                        </div>
                    </div>
                    <div class="pull-right" style="width: 85%">
                        <div class="center-menubar" id="center-menubar">
                            <ul>
                                <li
                                        id="study"
                                        style="cursor: pointer"
                                >
                                    <a href="#" @click="navEvent(1)">
                    <span
                            class="sticker radius"
                            style="background-color: rgb(255, 153, 51)"
                    ></span>
                                        <span class="font16" style="line-height: 32px"
                                        >单词基础</span
                                        >
                                        <!-- <br> -->
                                        <span class="font12" style="float: right">
                      <span class="fontDRed">●</span>
                      &nbsp;智能听写
                    </span>
                                    </a>
                                </li>
                                <li id="menu_voc_review" style="cursor: pointer" refresh="">
                                    <a href="#" @click="navEvent(2)">
                    <span
                            class="sticker radius"
                            style="background-color: rgb(153, 102, 102)"
                    ></span>
                                        <span class="font16" style="line-height: 32px"
                                        >记忆曲线</span
                                        ><br/>
                                        <span class="font12" style="float: right">
                      <span class="fontDRed">●</span>
                      &nbsp;智能复习
                    </span>
                                    </a>
                                </li>
                                <li id="reports" style="cursor: pointer">
                                    <router-link :to="{ name: 'study_study_report', params: {} }">
                    <span
                            class="sticker radius"
                            style="background-color: rgb(51, 102, 153)"
                    ></span>
                                        <span class="font16" style="line-height: 32px"
                                        >学习报告</span
                                        ><br/>
                                        <span class="font12" style="float: right">
                      <span class="fontDRed">●</span>&nbsp;数据统计
                    </span>
                                    </router-link>
                                </li>
                                <li id="wronwordBook" style="cursor: pointer">
                                    <router-link :to="{ name: 'study_ai_word_ai_word_wrong' }">
                                    <span
                                            class="sticker radius"
                                            style="background-color: rgb(255, 0, 110)"
                                    ></span>
                                                        <span class="font16" style="line-height: 32px"
                                                        >我的错词</span
                                                        ><br/>
                                                        <span class="font12" style="float: right">
                                      <span class="fontDRed">●</span>
                                      &nbsp;智学弱项
                                    </span>
                                    </router-link>
                                </li>
                                <li
                                        id="vocabularyBook"
                                        style="cursor: pointer"
                                        @click="slider"
                                >
                                    <a href="#">
                    <span
                            class="sticker radius"
                            style="background-color: rgb(204, 0, 51)"
                    ></span>
                        <span class="font16" style="line-height: 32px">排行榜</span><br/>
                        <span class="font12" style="float: right">
                      <span class="fontDRed">●</span>
                      &nbsp;我的荣耀
                    </span>
                                    </a>
                                </li>
                                <li id="independentTest" style="border-right-style: none;cursor: pointer">
                                    <a href="#" @click="shopVisible = true"
                                    ><span
                                            class="sticker radius"
                                            style="background-color: rgb(51, 153, 51)"
                                    ></span>
                                        <span class="font16" style="line-height: 32px;"
                                        >积分商城</span
                                        ><br/>
                                        <span class="font12" style="float: right">
                      <span class="fontDRed">●</span>
                      &nbsp;大有钱途
                    </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="count">
                <div class="count-time">
                    <span class="count-time__info">{{ jsq_times }}</span>
                </div>
            </div>
        </div>
        <router-view></router-view>
        <div class="girl">
            <!--            <video autoplay poster="../assets/images/shop/goods.png" controls>-->
            <!--                <source :src="videoSrc[videoIndex].src" type="video/mp4"/>-->
            <!--            </video>-->
        </div>
        <div class="boy"></div>
        <lightMusic class="light-music" ref="drag"/>
        <div :class="['ranking-list', isSlider ? 'sliders' : 'slider']" @click.stop="handleClick">
            <div class="ranking">
                <span v-if="isSlider === false" class="item-active" @click.stop="isSlider = true">排行榜</span>
                <template v-else v-for="(item, index) in rankItem">
                    <span :key="index" :class="[is_active === index + 1 ? 'item-active' : '']" @click.stop="changeItem(index + 1)">{{ item }}</span>
                </template>
            </div>
            <div class="list">
                <div class="list-title">
                    <div class="avatar">
                        <img :src="userRank.avatar" alt="我的头像">
                    </div>
                    <div class="ranking-num">
                        <span>{{ userRank.row_num }}</span>
                        <span>我的排名</span>
                    </div>
                    <el-divider direction="vertical"></el-divider>
                    <div class="ranking-gem">
<!--                        <span>{{ user.integral }}</span>-->
<!--                        <span>宝石数量</span>-->
                        <span>
                            <span>之前宝石</span>
                            <span>{{user.integral_old}}</span>
                        </span>
                        <span>
                            <span>现在宝石</span>
                            <span>{{user.integral}}</span>
                        </span>
                        <span>
                            <span>累计宝石</span>
                            <span>{{allIntegral}}</span>
                        </span>
                    </div>
                </div>
                <div class="list-option" style="display: none">
                    <div class="btn-prev">
                        <i class="fa fa-chevron-left"></i>
                    </div>
                    <div class="btn-next">
                        <i class="fa fa-chevron-right"></i>
                    </div>
                    <div class="list-option__item">
                        <span>段位</span>
                        <span class="option-on"></span>
                    </div>
                    <div class="list-option__item">
                        <span>等级榜</span>
                        <span></span>
                    </div>
                    <div class="list-option__item">
                        <span>荣誉榜</span>
                        <span></span>
                    </div>
                    <div class="list-option__item">
                        <span>金币</span>
                        <span></span>
                    </div>
                </div>
                <div class="ranking-content">
                    <div class="ranking-header">
                        <div v-if="is_active === 1" class="card-header__btn">
                            <el-button size="small" @click="getLast">上周</el-button>
                            <el-button size="small" @click="getCurrent">当前周</el-button>
                            <el-button size="small" @click="getNext">下周</el-button>
                        </div>
                        <div v-if="is_active === 2" class="card-header__btn">
                            <el-button size="small" @click="getLast">上月</el-button>
                            <el-button size="small" @click="getCurrent">当前月</el-button>
                            <el-button size="small" @click="getNext">下月</el-button>
                        </div>
                    </div>
                    <div class="card-title">
                        <span :class="{'span-hover':type==1}" @click="schoolEvent(1)">班级</span>
                        <span :class="{'span-hover':type==4}" @click="schoolEvent(4)">年级</span>
                        <span :class="{'span-hover':type==2}" @click="schoolEvent(2)">校区</span>
                        <span :class="{'span-hover':type==3}" @click="schoolEvent(3)">全部</span>
                    </div>
                    <ul class="ranking-entry" v-if="is_team === 0 || type !== 1">
                        <li class="ranking-entry__title">
                            <span>排名</span>
<!--                            <span style="margin-left: -50px;">拥有量</span>-->
                            <span>昵称</span>
                            <span>段位</span>
                        </li>
                        <li v-for="(item, index) in rankList" :key="index">
                            <span :class="['list-num',{'active':index<=2&&page===1}]">{{ item.row_num }}</span>
<!--                            <span class="gem-num">{{ item.integral_old }}</span>-->
                            <div class="list-nickname">
                                <div class="img-box">
                                    <img :src="item.avatar" alt="头像">
                                    <img :src="item.avatar_box" alt="">
                                </div>
                                <span>{{ item.truename }}</span>
                                <span>{{ item.integral }}</span>
                            </div>
                            <div class="list-level">
                                <img :src="item.level_img" alt="段位">
                                <!--                                <div class="hoist">-->
                                <!--                                    <i class="iconfont icon&#45;&#45;xiangshangjiantou"></i>-->
                                <!--                                    <i class="iconfont icon-icon-down"></i>-->
                                <!--                                    <span style="color: #e72856;font-size: 18px;">1</span>-->
                                <!--                                </div>-->
                            </div>
                        </li>
                    </ul>
                    <!-- 班级中战队排行榜 -->
                    <ul class="ranking-team" v-if="is_team === 1 && type === 1">
                        <template v-for="item in teamData">
                        <li :key="item.id">
                            <div class="ranking-team__item">
                                <template></template>
                                <div class="ranking-team__name">
                                    <img :src="[item.rank === 1 ? rankSrc[0].img : '']" alt="" v-if="item.rank === 1">
                                    <img :src="[item.rank === 2 ? rankSrc[1].img : '']" alt="" v-else-if="item.rank === 2">
                                    <img :src="[item.rank === 3 ? rankSrc[2].img : '']" alt="" v-else-if="item.rank === 3">
                                    <span v-else>{{ item.rank }}</span>
                                    <span>{{ item.name }}</span>
                                </div>
                                <div class="ranking-team__info">
                                    <img src="~@/assets/images/study/gold-logo.png" alt="">
                                    <span>平均宝石</span>
                                    <span class="info-num">{{ item.integral }}</span>
                                </div>
                            </div>
                            <template v-for="(s_item, s_index) in item.user_list">
                                <div class="ranking-team__members" :key="s_index" v-if="s_item.is_captain === 1">
                                    <div class="team-captain">
                                        <span>队长</span>
                                        <div class="img-box">
                                            <img :src="s_item.avatar" alt="头像">
                                            <img :src="s_item.avatar_box" alt="">
                                        </div>
                                    </div>
                                    <div class="ranking-team__members--info">
                                        <span>{{ s_item.truename }}</span>
                                        <span>{{ s_item.integral }}</span>
                                    </div>
                                    <div class="list-level">
                                        <img :src="s_item.level_img" :alt="s_item.level_name">
                                    </div>
                                </div>
                            </template>
                            <template v-for="(s_item, s_index) in item.user_list">
                                <div class="ranking-team__members" :key="s_index" v-if="s_item.is_captain === 0">
                                    <div class="img-box">
                                        <img :src="s_item.avatar" alt="头像">
                                        <img :src="s_item.avatar_box" alt="">
                                    </div>
                                    <div class="ranking-team__members--info">
                                        <span>{{ s_item.truename }}</span>
                                        <span>{{ s_item.integral }}</span>
                                    </div>

                                    <div class="list-level">
                                        <img :src="s_item.level_img" :alt="s_item.level_name">
                                    </div>
                                </div>
                            </template>
                        </li>
                        </template>
                    </ul>
                </div>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="prev, pager, next"
                        :pager-count="5"
                        :total="all_count"
                        v-if="is_team === 0 || type !== 1"
                >
                </el-pagination>
            </div>
        </div>
        <el-dialog
                class="shop-dialog"
                :visible.sync="shopVisible"
                v-if="shopVisible"
                width="1000px"
        >
            <div class="close-btn" @click="shopVisible = false">
                <i class="fa fa-close"></i>
            </div>
            <span class="shopping-title">积分商城</span>
            <shopping class="shopping"/>
        </el-dialog>
    </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import lightMusic from "@/views/study/light_music";
import shopping from "@/views/study/shopping";

export default {
    inject: ['reload'],
    components: {
        lightMusic,
        shopping
    },
    data() {
        return {
            is_active: 1,
            shopVisible: false,
            currentPage: 1,
            inside: true,
            isDisplay: false,
            pColor: "#7fb8f2",
            value: true,
            pWidth: 25,
            dialogVisible: false,
            jsq_times: "00.00.00",
            activeName: 'first',
            isSlider: true,
            type: 1,
            pageSize: 7,
            page: 1,
            rankList: [],
            all_count: 0,
            userRank: {},
            videoIndex: 0,
            is_team: 0,
            teamData: [],
            currentDate: 0,
            videoSrc: [
                {src: '../assets/video/girl.avi'},
                {src: '../assets/video/boy.avi'}
            ],
            rankItem: ['周榜', '月榜', '总榜'],
            rankSrc: [
                {
                    num: 1,
                    img: require('../assets/images/study/gold.png')
                },
                {
                    num: 2,
                    img: require('../assets/images/study/silver.png')
                },
                {
                    num: 3,
                    img: require('../assets/images/study/copper.png')
                }
            ]
        };
    },
    created() {
        this.jsq();
        this.getList();
        // setInterval(()=>{
        //     this.getList();
        // }, 30000)
        this.getTeamList()

        //console.log("errorWordCount",this.errorWordCount);
        if(this.user.is_error_word >0){
            this.getErrorWordCount()
        }else{
            this.$store.commit("setErrerWordCount",{uid:this.user.id,errorWordCount:0});
        }
        if(this.errorWordCount > 0){
            this.isSlider = false;
        }
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
            errorWordCount: (state) => state.user.errorWordCount,
        }),
        allIntegral(){
            let price = 0;
            price = parseInt(this.user.integral_old) + parseInt( this.user.integral);
            return price;
        }
    },
    methods: {
        relesh() {
            this.reload()
        },
        // 上周/上月
        getLast() {
            this.currentDate ++
            this.getList()
        },
        // 当前周/月
        getCurrent() {
            this.currentDate = 0
            this.getList()
        },
        // 下周/下月
        getNext() {
            this.currentDate--
            this.getList()
        },
        handleClick() {
            console.log('')
        },
        changeItem(val) {
            this.is_active = val
            this.isSlider = true
            console.log(222)
            this.currentDate = 0
            if(this.type==1 && this.is_team == 1){
                this.getTeamList()
            }else{
                this.getList()
            }

        },
        changeState() {
            this.isDisplay = false
            this.isSlider = false
            console.log(1111)
        },
        getTeamList() {
            this.axios
                    .post('/api/user/team_rank', {
                        token: this.token,
                        rankType: this.is_active,
                        diffNum: this.currentDate,
                         rankType: this.is_active,
                        diffNum: this.currentDate
                    }).then(res => {
                        this.teamData = res.data.data.lists;
                        this.teamData.length !== 0 ? this.is_team = 1 : this.is_team = 0;
                        console.log(this.teamData)
                    })
        },
        navEvent(index) {
            //this.$store.commit('setPage', index);
        },
        // 获取排行
        getList() {
            console.log('类型数据：', this.is_active, this.currentDate)
            this.axios
                    .post('/api/user/user_rank', {
                        token: this.token,
                        get_type: this.type,
                        pageSize: this.pageSize,
                        page: this.page,
                        rankType: this.is_active,
                        diffNum: this.currentDate
                    })
                    .then(res => {
                        this.rankList = res.data.data.lists;
                        this.all_count = res.data.data.all_count;
                        this.userRank = res.data.data.userRank;
                    })
        },
        schoolEvent(type) {
            this.type = type;
            this.page = 1;
            if(this.is_team == 1 && type == 1){
                this.getTeamList()
            }else{
                this.getList()
            }

        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getList();

            console.log(`当前页: ${val}`);
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        format(percentage) {
            return `${percentage}`;
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                    .then((_) => {
                        done();
                    })
                    .catch((_) => {
                    });
        },
        logout() {
            //console.log("退出登陆");
            this.axios
                    .post("/api/auth/logout", {token: this.token, get_type: this.type,})
                    .then((res) => {
                    })
                    .catch((err) => {
                        this.loading = false;
                    });
            this.$store.commit("logout");
            this.$router.push({name: "login"});
        },
        jsq() {
            //点击开始建 开始计数
            let that = this;
            var uid = this.user.id;
            //console.log("uid:" + uid);
            var s = window.sessionStorage.getItem("jqx_count" + uid);
            var count = 0;
            if (s) {
                //console.log("sessionStorage:" + s);
                count = s;
            }
            var timer = null; //timer变量记录定时器setInterval的返回值
            timer = setInterval(function () {
                count++;
                window.sessionStorage.setItem("jqx_count" + uid, count);
                //console.log(count);
                // 需要改变页面上时分秒的值
                //console.log($("id_S"));
                var times_str = "";
                times_str += that.showNum(parseInt(count / 60 / 60)) + ":";
                times_str += that.showNum(parseInt(count / 60) % 60) + ":";
                times_str += that.showNum(count % 60);
                //console.log(times_str);
                that.jsq_times = times_str;
            }, 1000);
        },
        //封装一个处理单位数字的函数
        showNum(num) {
            if (num < 10) {
                return "0" + num;
            }
            return num;
        },
        // 排行榜抽屉
        slider() {
            if (this.isSlider) {
                this.$refs.ranking.classList.remove('sliders');
                this.$refs.ranking.classList.add('slider');
                this.isSlider = false;
            } else {
                this.$refs.ranking.classList.remove('slider');
                this.$refs.ranking.classList.add('sliders');
                this.isSlider = true;
            }
        },
        getErrorWordCount(){
            this.axios
                    .post('/api/course/get_error_word_count', {
                        token: this.token
                    })
                    .then(res => {
                        let data = res.data.data;
                        this.$store.commit("setErrerWordCount",{uid:this.user.id,errorWordCount:data.error_word_count});
                        if(this.errorWordCount > 0){
                            this.isSlider = false;
                        }
                    })
        }
    },
};
</script>

<style lang="scss">
@import "~@/assets/css/ai_word.scss";

@media screen and (min-width: 375px) and (max-width: 767px) {
    html {
        font-size: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    html {
        font-size: 63px !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    html {
        font-size: 70px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1440px) {
    html {
        font-size: 80px;
    }
}

//@media screen and (min-width: 1366px) and (max-width: 1440px) {
//    font-size: 100px;
//}

@media screen and(min-width: 1441px) and (max-width: 1920px) {
    html {
        font-size: 100px;
    }
}

@media screen and (min-width: 1920px) and (max-width: 7680px) {
    html {
        font-size: 100px;
    }
}

@media (min-width: 1200px) {
    .container,
    .header {
        width: 1170px;
    }
}
[v-cloak] {
    display: none;
}
.light-music {
    position: fixed;
    right: 0;
    bottom: 0;
}

.girl {
    position: fixed;
    left: 0;
    bottom: 0;
}

.el-loading-mask .el-loading-spinner {
    width: 300px;
    padding: 20px;
    left: 50%;
    margin-left: -150px;

}

.el-loading-mask .el-icon-loading {
    font-size: 50px;
    color: #F2A454;
}

.el-loading-mask .el-loading-text {
    font-size: 20px;
    color: #ffffff;
}

@keyframes spin {
    0%,
    15% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader {
    _background: #000;
    _background: -webkit-radial-gradient(#222, #000);
    _background: radial-gradient(#222, #000);
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99999;

    &-inner {
        bottom: 0;
        height: 60px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 100px;
    }
}
</style>
